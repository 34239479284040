import { EnvironmentModel } from '@root/environments/environment.model';

export const environment: EnvironmentModel = {
  production: true,
  use_custom_error_handler: true,
  enable_raygun: true,
  raygun_identifier: 'dev1',
  raygun_api_key: 'etjpgKhgjBXUIfb5edpoLQ',
  BASE_URL: 'https://app-dev1.thetalake.com',
  API_URL: 'https://app-dev1.thetalake.com/api/v1',
  INGESTER_API_URL: 'https://ingesterapi-dev1.thetalake.com/api/v1',
  ACTION_CABLE_URL: 'wss://app-dev1.thetalake.com/cable',
  // eslint-disable-next-line max-len
  GOJS_LICENSE: '298647e0b3624fc702d90676423d6bf919a42963cf841ca00b0416f6bd08381d759cef2a55d7d9c680f846ee0a7ac989ddc56d7ac41e0738e431d1d543e680f0e23371b417081089f605249091fe2cf6ab6a61f497e571a288288de0fbabc29f59f7f1cb48c9',
  PENDO_ENABLED: true,
  CHURN_ZERO_ENABLED: true,
  CHURN_ZERO_APP_KEY: '1!mxilbpbxLMxC06ALHS93ATj8RlwMh05pEipUHQEzaQst13B7',
  CHURN_ZERO_URL: 'https://thetalake-dev.us1app.churnzero.net/churnzero.js'
};
